import React from 'react'
import styled from 'styled-components'
import { Button, useWalletModal } from '@migration/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const Button2 = styled(Button)`
  width: 100%;
  background-color: #000;
  color: white;

  ${({ theme }) => theme.mediaQueries.md} {
    min-width: 160px;
  }
`

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return (
    <Button2 onClick={onPresentConnectModal} {...props}>
      {t('Connect Wallet')}
    </Button2>
  )
}

export default ConnectWalletButton

import { ChainId, Token } from '@pyeclaim/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  weth: new Token(
    MAINNET,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped ETH',
    'https://weth.io/',
  ),
  // eth here points to the weth contract. Wherever the currency ETH is required, conditional checks for the symbol 'ETH' can be used
  eth: new Token(MAINNET, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 18, 'ETH', 'ETH', 'https://weth.io/'),
  usdc: new Token(
    MAINNET, 
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 
    6, 
    'USDC', 
    'USD Coin', 
    'https://www.centre.io/'
  ),
  usdt: new Token(
    MAINNET, 
    '0xdAC17F958D2ee523a2206206994597C13D831ec7', 
    6, 
    'USDT', 
    'Tether USD',
    'https://tether.to/',
  ),  
  dai: new Token(
    MAINNET, 
    '0x6B175474E89094C44Da98b954EedeAC495271d0F', 
    18, 
    'DAI', 
    'Dai Stablecoin',
    'https://makerdao.com/'
  ),
  wbtc: new Token(
    MAINNET, 
    '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 
    8, 
    'WBTC', 
    'Wrapped BTC', 
    'https://www.wbtc.network/',
  ),
  pye: new Token(
    MAINNET,
    '0x5B232991854c790b29d3F7a145a7EFD660c9896c',
    9,
    'PYE',
    'PYE Token',
    'https://pye.net/',
  ),
  apple: new Token(
    MAINNET,
    '0x6f43a672D8024ba624651a5c2e63D129783dAd1F',
    18,
    'APPLEPYE',
    'ApplePYE',
    'https://pye.net/',
  ),
  cherry: new Token(
    MAINNET,
    '0xD2858A1f93316242E81CF69B762361F59Fb9b18E',
    18,
    'CHERRYPYE',
    'CherryPYE',
    'https://pye.net/',
  ),
  // force: new Token(
  //   MAINNET,
  //   '0xcD9bc85C6b675DA994F172Debb6Db9BDD6727FE7',
  //   9,
  //   'FORCE',
  //   'MoonForce',
  //   'https://moonforce.com/',
  // ),
  // minidoge: new Token(
  //   MAINNET,
  //   '0xBa07EED3d09055d60CAEf2bDfCa1c05792f2dFad',
  //   9,
  //   'MINIDOGE',
  //   'MiniDOGE',
  //   'https://minidoge.finance/',
  // ),
  // symbull: new Token(
  //   MAINNET,
  //   '0xA176fa55bef56D18ab671251957aCB0Db630539b',
  //   9,
  //   'SYMBULL',
  //   'SymBULL',
  //   'https://symbull.com/',
  // ),
  // magic: new Token(
  //   MAINNET,
  //   '0xA912A448024c5DAE31C753696A0149eD0C4293c5',
  //   18,
  //   'MAGIC',
  //   'Magic Swap',
  //   'https://pye.net/',
  // ),
}


export const testnetTokens = {
  weth: new Token(
    TESTNET,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH',
    'Wrapped Ether',
  ),
  // eth here points to the weth contract. Wherever the currency ETH is required, conditional checks for the symbol 'ETH' can be used
  eth: new Token(TESTNET, '0xc778417E063141139Fce010982780140Aa0cD5Ab', 18, 'ETH', 'ETH'),
  usdc: new Token(
    TESTNET, 
    '0xeb8f08a975Ab53E34D8a0330E0D34de942C95926', 
    6, 
    'USDC', 
    'USD Coin (rinkeby)', 
  ),
  usdt: new Token(
    TESTNET, 
    '0x3B00Ef435fA4FcFF5C209a37d1f3dcff37c705aD', 
    6, 
    'USDT', 
    'Tether USD (rinkeby)', 
  ),
  dai: new Token(
    TESTNET, 
    '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735', 
    18, 
    'DAI', 
    'Dai Stablecoin', 
  ),
  wbtc: new Token(
    TESTNET, 
    '0x577D296678535e4903D59A4C929B718e1D575e0A', 
    8, 
    'WBTC', 
    'Wrapped BTC', 
  ),
  pye: new Token(
    TESTNET,
    '0x5B232991854c790b29d3F7a145a7EFD660c9896c',
    9,
    'PYE',
    'PYE',
    'https://pye.net/',
  ),
  apple: new Token(
    TESTNET,
    '0x4839D1eD94CB344F3671828Bbe51Af5f95493D46',
    18,
    'APPLE',
    'APPLE',
    '',
  ),
  cherry: new Token(
    TESTNET,
    '0xF6D862573b271591e3FB98841d100523e96c9C3A',
    18,
    'CHERRY',
    'CHERRY',
    '',
  ),
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(testnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || testnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
